var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isOpen,
      expression: "isOpen"
    }],
    staticClass: "collapse-btn",
    attrs: {
      "elevation": "2"
    },
    on: {
      "click": _vm.panelsCollapse
    }
  }, [_vm._v(" Collapse All ")]), _c('v-expansion-panels', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.panel,
      callback: function callback($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, _vm._l(_vm.sections, function (section) {
    var _vm$subApplication, _vm$subApplication2, _vm$subApplication2$d;

    return _c('v-expansion-panel', {
      key: section.key
    }, [section.visibility ? _c(section.component, {
      key: section.key,
      tag: "component",
      attrs: {
        "section": section,
        "parentId": (_vm$subApplication = _vm.subApplication) === null || _vm$subApplication === void 0 ? void 0 : _vm$subApplication.id,
        "existedData": (_vm$subApplication2 = _vm.subApplication) === null || _vm$subApplication2 === void 0 ? void 0 : (_vm$subApplication2$d = _vm$subApplication2.data) === null || _vm$subApplication2$d === void 0 ? void 0 : _vm$subApplication2$d[section.key]
      }
    }) : _vm._e()], 1);
  }), 1), _c('br'), _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isOpen,
      expression: "isOpen"
    }],
    staticClass: "collapse-btn",
    attrs: {
      "elevation": "2"
    },
    on: {
      "click": function click($event) {
        return _vm.panelsCollapseExtended("expansion-".concat(_vm.subApplication.id));
      }
    }
  }, [_vm._v(" Collapse All ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }