<template>
  <p class="subApplicant-title">
    <template v-if="draft">[DRAFT] </template>
    {{ title }}
  </p>
</template>

<script>
export default {
  name: 'SubApplicationTitle',

  props: {
    subApplicant: {
      type: Object,
      default: () => {},
    },

    draft: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    title() {
      const lastName = this.subApplicant?.data?.personal_details?.last_name;
      const firstName = this.subApplicant?.data?.personal_details?.first_name;

      const titleExist = Boolean(lastName || firstName);

      if (titleExist) {
        return `${lastName} ${firstName}`;
      }

      return `Please Complete (#${this.subApplicant?.id})`;
    },
  },
};
</script>

<style scoped>
.subApplicant-title {
  margin-bottom: 0;
  font-size: 21px;
  font-weight: 500;
}
</style>
