<template>
  <div>
    <v-btn
      v-show="isOpen"
      class="collapse-btn"
      elevation="2"
      @click="panelsCollapse"
    >
      Collapse All
    </v-btn>
    <v-expansion-panels
      v-model="panel"
      multiple
    >
      <v-expansion-panel
        v-for="section in sections"
        :key="section.key"
      >
        <component
          v-if="section.visibility"
          :is="section.component"
          :key="section.key"
          :section="section"
          :parentId="subApplication?.id"
          :existedData="subApplication?.data?.[section.key]"
        />
      </v-expansion-panel>
    </v-expansion-panels>
    <br>
    <v-btn
      v-show="isOpen"
      class="collapse-btn"
      elevation="2"
      @click="panelsCollapseExtended(`expansion-${subApplication.id}`)"
    >
      Collapse All
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ExtensionPanels',

  components: {
    PersonalDetailsSection: () => import('@/components/SubApplications/Sections/PersonalDetailsSection.vue'),
    EmploymentsSection: () => import('@/components/SubApplications/Sections/EmploymentsSection.vue'),
    AddressHistorySection: () => import('@/components/SubApplications/Sections/AddressHistorySection.vue'),
  },

  props: {
    subApplication: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      panel: [],
      sections: [
        {
          key: 'personal_details',
          component: 'PersonalDetailsSection',
          title: 'Personal details of Applicant',
          icon: 'mdi-account-check-outline',
          visibility: true,
          access: ['client', 'adviser'],
          payloadKey: 'personalDetails',
          subApplicant: true,
        },

        {
          key: 'employments',
          component: 'EmploymentsSection',
          title: 'Employments Details',
          icon: 'mdi-briefcase-outline',
          visibility: true,
          access: ['client', 'adviser'],
          payloadKey: 'employments',
          subApplicant: true,
        },

        {
          key: 'address_history',
          component: 'AddressHistorySection',
          title: 'Address History',
          icon: 'mdi-home-edit',
          visibility: true,
          access: ['client', 'adviser'],
          payloadKey: 'addressHistory',
          subApplicant: true,
        },
      ],
    };
  },

  computed: {
    isOpen() {
      const panelIsNotEmpty = Boolean(this.panel.length > 0);

      return panelIsNotEmpty;
    },
  },

  methods: {
    panelsCollapse() {
      this.panel = [];
    },

    panelsCollapseExtended(id) {
      this.panel = [];
      this.$emit('panelsCollapseExtended', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.collapse-btn {
  margin-bottom: 20px;
}
</style>
