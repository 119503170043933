<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="false"
    :section="section"
  >
    <template #section>
      <v-toolbar
        elevation="0"
      >
        <v-btn
          @click="createSubApplication"
          :disabled="FIELDS_DISABLED && !IS_CLIENT"
          text>
          <v-icon>mdi-account-plus</v-icon>
          NEW APPLICANT
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-4">
        <div
          v-for="subApplication in subApplicationsActive"
          :key="subApplication.id"
          :id="`expansion-${subApplication.id}`"
        >
          <div class="top-row">
            <SubApplicationTitle
              :subApplicant="subApplication"
            >
            </SubApplicationTitle>
            <v-btn
              class="toggle-btn"
              :disabled="FIELDS_DISABLED"
              tile
              icon
              @click="toggleApplication(subApplication, 'draft')"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
          <ExtensionPanels
            :subApplication="subApplication"
            @panelsCollapseExtended="panelsCollapseExtended"
          >
          </ExtensionPanels>
          <v-divider></v-divider>
          <br>
        </div>
        <template v-if="isDrafts">
          <v-expand-transition>
            <v-list
              dense
              v-if="isShowDrafts"
            >
              <v-list-item-group>
                <div class="top-row"
                  v-for="subApplication in subApplicationsDrafts"
                  :key="subApplication.id"
                >
                  <SubApplicationTitle
                    :subApplicant="subApplication"
                    :draft="true"
                  >
                  </SubApplicationTitle>
                  <v-btn
                    class="toggle-btn"
                    :disabled="FIELDS_DISABLED"
                    tile
                    icon
                    @click="toggleApplication(subApplication, 'active')"
                  >
                    <v-icon>mdi-restore</v-icon>
                  </v-btn>
                </div>
              </v-list-item-group>
            </v-list>
          </v-expand-transition>
          <v-expand-transition>
            <v-btn
              block
              @click="toggleDraft"
              :disabled="FIELDS_DISABLED"
            >
              {{ toggleDraftLabel }}
            </v-btn>
          </v-expand-transition>
        </template>
      </v-card-text>
    </template>
  </BaseSection>
</template>

<script>
import { mapGetters } from 'vuex';

import { getSubApplications, setApplicantStatus } from '@/services/firebase';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';
import SubApplicationTitle from '@/components/SubApplications/Sections/Components/SubApplicationTitle.vue';
import ExtensionPanels from '@/components/SubApplications/Sections/ExtensionPanels.vue';

export default {
  name: 'SubApplications',

  components: {
    BaseSection,
    SubApplicationTitle,
    ExtensionPanels,
  },

  mixins: [SectionMixin],

  props: {
    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isShowDrafts: false,
      isSectionVisible: true,
    };
  },

  computed: {
    ...mapGetters(['APPLICATION', 'SUB_APPLICATIONS', 'FIELDS_DISABLED', 'IS_CLIENT']),

    parentId() {
      return this.$route.params.id;
    },

    subApplicationsActive() {
      const applicants = this.SUB_APPLICATIONS;
      const activeApplicants = applicants.filter((item) => item.status !== 'draft');

      return activeApplicants;
    },

    subApplicationsDrafts() {
      const applicants = this.SUB_APPLICATIONS;
      const draftApplicants = applicants.filter((item) => item.status === 'draft');

      return draftApplicants;
    },

    isDrafts() {
      const isDrafts = Boolean(this.subApplicationsDrafts.length);

      return isDrafts;
    },

    toggleDraftLabel() {
      const label = this.isShowDrafts
        ? 'Hide Drafts'
        : 'Show Drafts';

      return label;
    },
  },

  methods: {
    panelsCollapseExtended(id) {
      this.goToSection(id);
    },

    goToSection(sectionId) {
      try {
        this.$vuetify.goTo(`#${sectionId}`, {
          duration: 300,
          offset: 0,
          easing: 'easeInOutCubic',
        });
      } catch (error) {
        console.log(error);
      }
    },

    async createSubApplication() {
      const newApplicant = await this.$store.dispatch('createApplication', this.parentId);
      const newApplicantId = await newApplicant.id;
      this.goToSection(`expansion-${newApplicantId}`);
    },

    async toggleApplication(subApplication, newStatus) {
      const payload = {
        id: subApplication.id,
        parentId: subApplication.parentId,
        status: newStatus,
      };

      const editedApplication = await this.$store.dispatch('toggleApplicationStatus', payload);

      await setApplicantStatus(editedApplication);
    },

    toggleDraft() {
      this.isShowDrafts = !this.isShowDrafts;
    },
  },

  async created() {
    await getSubApplications(this.$route.params.id);

    this.$emit('componentReady');
  },
};
</script>

<style scoped>
.top-row {
  margin-bottom: 21px;
  display: flex;
  align-items: center;
}

.draft .top-row {
  margin-bottom: 0;
}

.toggle-btn {
  margin-left: 15px;
}
</style>
