var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": false,
      "section": _vm.section
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "elevation": "0"
          }
        }, [_c('v-btn', {
          attrs: {
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT,
            "text": ""
          },
          on: {
            "click": _vm.createSubApplication
          }
        }, [_c('v-icon', [_vm._v("mdi-account-plus")]), _vm._v(" NEW APPLICANT ")], 1)], 1), _c('v-card-text', {
          staticClass: "mt-4"
        }, [_vm._l(_vm.subApplicationsActive, function (subApplication) {
          return _c('div', {
            key: subApplication.id,
            attrs: {
              "id": "expansion-".concat(subApplication.id)
            }
          }, [_c('div', {
            staticClass: "top-row"
          }, [_c('SubApplicationTitle', {
            attrs: {
              "subApplicant": subApplication
            }
          }), _c('v-btn', {
            staticClass: "toggle-btn",
            attrs: {
              "disabled": _vm.FIELDS_DISABLED,
              "tile": "",
              "icon": ""
            },
            on: {
              "click": function click($event) {
                return _vm.toggleApplication(subApplication, 'draft');
              }
            }
          }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1), _c('ExtensionPanels', {
            attrs: {
              "subApplication": subApplication
            },
            on: {
              "panelsCollapseExtended": _vm.panelsCollapseExtended
            }
          }), _c('v-divider'), _c('br')], 1);
        }), _vm.isDrafts ? [_c('v-expand-transition', [_vm.isShowDrafts ? _c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item-group', _vm._l(_vm.subApplicationsDrafts, function (subApplication) {
          return _c('div', {
            key: subApplication.id,
            staticClass: "top-row"
          }, [_c('SubApplicationTitle', {
            attrs: {
              "subApplicant": subApplication,
              "draft": true
            }
          }), _c('v-btn', {
            staticClass: "toggle-btn",
            attrs: {
              "disabled": _vm.FIELDS_DISABLED,
              "tile": "",
              "icon": ""
            },
            on: {
              "click": function click($event) {
                return _vm.toggleApplication(subApplication, 'active');
              }
            }
          }, [_c('v-icon', [_vm._v("mdi-restore")])], 1)], 1);
        }), 0)], 1) : _vm._e()], 1), _c('v-expand-transition', [_c('v-btn', {
          attrs: {
            "block": "",
            "disabled": _vm.FIELDS_DISABLED
          },
          on: {
            "click": _vm.toggleDraft
          }
        }, [_vm._v(" " + _vm._s(_vm.toggleDraftLabel) + " ")])], 1)] : _vm._e()], 2)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }