var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "subApplicant-title"
  }, [_vm.draft ? [_vm._v("[DRAFT] ")] : _vm._e(), _vm._v(" " + _vm._s(_vm.title) + " ")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }